/* eslint-disable no-unused-vars */
<template>
  <section class="invoice-preview-wrapper">
    <b-card title="Group Invoice Generate">
      <!-- select & search -->
      <div class="custom-search row">
        <!-- Campus Dropdown -->
        <b-form-group
          label-for="campus"
          class="pr-0 col-lg-3 col-md-3 col-sm-6"
        >
          <v-select
            v-model="searchForm.campus"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            placeholder="Select Campus"
            :options="campuses"
            :reduce="(val) => val.id"
            :disabled="isLoading || (campuses.length ? false : true)"
          />
        </b-form-group>

        <!-- Class Dropdown -->
        <b-form-group
          label-for="class"
          class="pr-0 col-lg-3 col-md-3 col-sm-6"
        >
          <v-select
            v-model="searchForm.class"
            :disabled="isLoading || (classes.length ? false : true)"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            placeholder="Select Class"
            :options="classes"
            :reduce="(val) => val"
            @input="(val) => onChangeClass(val)"
          />
        </b-form-group>
        <!-- Session Dropdown -->
        <b-form-group
          label-for="session"
          class="pr-0 col-lg-2 col-md-2 col-sm-6"
        >
          <v-select
            v-model="searchForm.session"
            :disabled="isLoading || (sessions.length ? false : true)"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            placeholder="Select Session"
            :options="sessions"
            :reduce="(val) => val.id"
          />
        </b-form-group>
        <!-- Shift Dropdown -->
        <b-form-group
          label-for="shift"
          class="pr-0 col-lg-2 col-md-2 col-sm-6"
        >
          <v-select
            v-model="searchForm.shift"
            :disabled="
              isLoading || (shiftData.length ? false : true) || !hasShift
            "
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            placeholder="Select Shift"
            :options="shiftData"
            :reduce="(val) => val.id"
          />
        </b-form-group>
        <!-- Section Dropdown -->
        <b-form-group
          label-for="section"
          class="pr-0 col-lg-2 col-md-2 col-sm-6"
        >
          <v-select
            v-model="searchForm.section"
            :disabled="isLoading || (sections.length ? false : true)"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            placeholder="Select Section"
            :options="sections"
            :reduce="(val) => val.section_id"
          />
        </b-form-group>
      </div>
      <div class="row">
        <!-- Search Dropdown -->
        <b-form-group
          label-for="calculate"
          class="pr-0 col-lg-2 col-md-2 col-sm-6"
        >
          <b-button
            variant="outline-success"
            :disabled="isLoading"
            @click="onDownload"
          >
            Download
          </b-button>
        </b-form-group>
        <!-- Clear Dropdown -->
        <b-form-group
          label-for="calculate"
          class="pr-0 col-lg-2 col-md-2 col-sm-6"
        >
          <b-button
            variant="outline-danger"
            :disabled="isLoading"
            @click="onClear"
          >
            Reset
          </b-button>
        </b-form-group>
      </div>

      <preloader v-if="isLoading" />
    </b-card>
  </section>
</template>

<script>
import {
  BCard,
  BFormGroup,
  VBModal,
  BButton,
  //   BRow,
  //   BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store/index'
import FemsToastrService from '@/@service/utils/FemsToastr.service'
import { generatePDF } from '@/@service/utils/InvoicePdfGenerator'
import AccGroupInvoiceService from '@/@service/api/accounting/AccGroupInvoice.service'
import LocalStorageService from '@/@service/utils/LocalStorage.service'
import Preloader from '../components/preloader/preloader.vue'

export default {
  components: {
    BCard,
    BFormGroup,
    vSelect,
    BButton,
    Preloader,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      getPageData: JSON.parse(LocalStorageService.get('pageData')) || '',

      props: {},
      paymentInput: null,
      is_show: false,
      checkboxStatus: 'not_checked',
      isFeeReason: false,
      /*
       * variable for adj
       */

      pageLength: 10,
      dir: false,
      rows: [],
      tempRows: [],

      type: 1,
      campuses: [],
      classes: [],
      sessions: [],
      shiftData: [
        { id: 1, name: 'Morning' },
        { id: 2, name: 'Day' },
      ],
      sections: [],

      searchForm: {
        session: '',
        campus: '',
        class: '',
        shift: '',
        section: '',
        classId: '',
      },
      isLoading: false,
      hasShift: false,
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    'searchForm.classId': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.sessions = []
        this.getSession()
      }
    },
    'searchForm.session': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.sections = []
        this.getSection()
      }
    },
  },
  created() {
    this.getClass()
    this.getCampus()
  },
  mounted() {},
  methods: {
    // search data
    onDownload() {
      if (this.validation()) return

      this.isLoading = true
      AccGroupInvoiceService.getAllInvoice(this.searchForm)
        .then(response => {
          const res = response.data
          this.isLoading = false
          if (res.data.length) {
            const invoiceData = res.data
            const invoiceName = `${invoiceData[0].studentData.campus}-${invoiceData[0].studentData.class}-${invoiceData[0].studentData.section}`

            generatePDF(this.getPageData, invoiceData, invoiceName)
            this.onClear()
            return
          }
          FemsToastrService.info(res.message)
        })
        .catch(error => {
          this.isLoading = false
          FemsToastrService.info(error.message)
        })
    },
    // on clear
    onClear() {
      this.searchForm = {
        session: '',
        campus: '',
        class: '',
        shift: '',
        section: '',
        classId: '',
      }
      this.session = []
      this.sesction = []

      this.hasShift = false
    },

    getCampus() {
      AccGroupInvoiceService.getAllCampus().then(response => {
        const res = response.data
        this.campuses = res.data
      })
    },
    getClass() {
      AccGroupInvoiceService.getAllClass().then(response => {
        const res = response.data
        this.classes = res.data
      })
    },
    onChangeClass(value) {
      this.searchForm.classId = value.id
      this.hasShift = value.has_shift
      this.searchForm.session = ''
      this.searchForm.shift = ''
      this.searchForm.section = ''
    },
    getSession() {
      if (!this.searchForm.classId) {
        this.sessions = []
        return
      }
      AccGroupInvoiceService.getAllSecSession(this.searchForm.classId).then(
        response => {
          const res = response.data
          this.sessions = res.data
        },
      )
    },

    getSection() {
      if (!this.searchForm.classId || !this.searchForm.session) {
        this.sections = []
        return
      }
      AccGroupInvoiceService.getAllSection(
        this.searchForm.classId,
        this.searchForm.session,
        this.searchForm.shift,
      ).then(response => {
        const res = response.data
        this.sections = res.data
      })
    },

    validation() {
      if (!this.searchForm.campus) {
        FemsToastrService.error('Campus Field is required')
        return true
      }
      if (!this.searchForm.classId) {
        FemsToastrService.error('Class Field is required')
        return true
      }
      if (!this.searchForm.session) {
        FemsToastrService.error('Session Field is required')
        return true
      }
      if (this.hasShift && !this.searchForm.shift) {
        FemsToastrService.error('Shift Field is required')
        return true
      }
      if (this.sections.length && !this.searchForm.section) {
        FemsToastrService.error('Section Field is required')
        return true
      }
      return false
    },
  },
  /*
   * custom payment
   */
}
</script>
<style lang="scss">
.text-mt {
  margin-top: 8px;
}
</style>
