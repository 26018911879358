import axiosIns from '@/libs/axios'

export default {
  getAllInvoice: searchForm => axiosIns.get(`accounting/acc-std-charge-fee/group-invoice-printing?session=${searchForm.session}&campus=${searchForm.campus}&class=${searchForm.classId}&shift=${searchForm.shift}&section=${searchForm.section}`),

  getAllCampus: () => axiosIns.get('accounting/acc-student-concession/campuses'),
  getAllClass: () => axiosIns.get('accounting/acc-student-concession/classes'),
  getAllSecSession: classId => axiosIns.get(`accounting/acc-std-charge-fee/sec-sessions/${classId}`),
  getAllSection: (classId, sessionId, shiftId) => axiosIns.get(`accounting/acc-std-charge-fee/section/${classId}/${sessionId}${shiftId ? `/${shiftId}` : ''}`),

}
